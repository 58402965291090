import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { useHomeSparkleContent } from "../hooks/home-sparkle-content-hook"
import styled from "styled-components"

function HomeGrid() {
  const { edges } = useHomeSparkleContent();

  const Container = styled.div`
    a, button {
      color: inherit;
      text-decoration: none;
    }
    
  `
  const Unit = styled.div`
    display: flex;
    margin: 2rem 0;
  `
  const Title = styled.h1`
    width: 80%;
    font-size: 1rem;
    font-family: "Latin Modern Roman";
    text-transform: lowercase;
    color: mediumblue;
    :hover {
      opacity: .7;
    }
  `
  const Frontmatter = styled.h2`
    width: 7rem;
    font-family: "Latin Modern Mono";
    font-weight: bold;
    padding: .4rem;
    padding-left: 0;
    font-size: .5rem;
    color: grey;
    // background: white;
    text-transform: lowercase;
`
  const Date = styled.span`
    // background: white;
    border-bottom: 1px solid mediumblue;
    :hover {
      color: darkorchid;
    }
  `
  const Accent = styled.div`
  // position: absolute;
  // top: .9rem;
  // left: -3.5rem;
  // font-size: 2rem;
`
  const BlurBox = styled.div`
    // position: absolute;
    // z-index: -90;
    width: 30px;
    height: 30px;
    // top: -75%;
    // left: -100%;
    background: blue;
    filter: blur(30px);
    border-radius: 140px;
  `

  return (
    <Container>
      {/* <BlurBox></BlurBox> */}
      {edges.map(({ node }, i) => (
        <Unit id={i}>
          <Frontmatter>
            <Date>
              <Link to={node.fields.slug}>{node.frontmatter.date}</Link>
            </Date>
          </Frontmatter>
          <Title title="sample; click to read article">
            <Link to={node.fields.slug}>
              {node.frontmatter.title}
            </Link>
          </Title>
        </Unit>
      ))}
    </Container>
  );
}

export default HomeGrid;