import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Layout from "../components/layout"
// import Carousel from "../components/carousel"
import HomeGrid from "../components/homegrid"
// import Turner from "../components/turner"
import SEO from "../components/seo"

const Hyper = styled.h1`
  margin: 0 auto;
  margin-top: .8rem;
  margin-bottom: 3rem;
  width: 25rem;
  text-align: center;
  font-size: 5rem;
  font-family: "Latin Modern Roman";
  font-style: italic;
  color: white;
  ${'' /* background: lavenderblush; */}
  -webkit-text-stroke: 1px blue;
  a {
    text-decoration: none;
    color: white;
    -webkit-text-stroke: 1px blue;
  }
  :hover #what {
    fill: blue;
  }
`

const What = styled.h1`
margin-right: .39rem;
margin-left: auto;
margin-top: -5.3rem;
width: 4rem;
height: 2rem;
font-size: 1.2rem;
path {
  fill: transparent;
}
text, a {
  font-family: "Latin Modern Mono";
  fill: white;
  transition: fill 2s;
  }
}
`

const Disclaimer = styled.div`
  margin: 0;
  font-size: .5rem;
  color: blue;
`

const IndexPage = () => (
  <Layout>
    <SEO title="narus_news" />
    <Hyper>
      updatez*
      <What>
        <svg viewBox="-20 -20 150 150">
          <path id="curve" d="M 0 100 A 50 50 0 1 0 100 0" />
          <text x="0">
            <textPath href="#curve">
              <Link to="about" id="what">what's this about?</Link>
          </textPath>
          </text>
        </svg>
      </What>
    </Hyper>
    <HomeGrid />
    <Disclaimer>THE RECOMMENDED POSTS ARE MARKED WITH A STAR.</Disclaimer>
    {/* <Turner /> */}
  </Layout>
)

export default IndexPage
