import { useStaticQuery, graphql } from "gatsby"

export const useHomeSparkleContent = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query HomeSparkleContentQuery {
        allMarkdownRemark(sort: {fields: frontmatter___date, order: ASC}, filter: {frontmatter: {category: {in: "updates"}, type: {eq: "post"}}}, limit: 100) {
          totalCount
          edges {
            node {
              id
              html
              fields {
                slug
              }
              frontmatter {
                date(formatString: "DD MMMM YYYY")
                title
                description
                category
                tags
                accent
              }
            }
          }
        }
      }
    `
  )
  return allMarkdownRemark
}